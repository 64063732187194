/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    background: #222;
    transition: all 0.5s;
    z-index: 997;
    height: 86px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #header.fixed-top {
    height: 70px;
  }
  
  #header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.8px;
    font-family: "Poppins", sans-serif;
  }
  
  #header .logo a {
    color: #fff;
  }
  
  #header .logo a span {
    color: #50AE5A;
  }
  
  #header .logo img {
    max-height: 54px;
  }
  
  .scrolled-offset {
    margin-top: 70px;
  }
  
  #header .container {
    margin-left: 20px;
    /*margin-right: 0;*/
  }

  /*************************************************************/
  .logo:before {
    content: '';
    position: absolute;
    /*top: 150px;*/
    margin-top: -16px;
    left: -100px;
    width: 70px;
    height: 86px;
    background: rgba(255,255,255, 0.3);
    transform: skewX(-30deg);
    animation-name: slide;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-delay: 1.3s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    background: linear-gradient(
        to right, 
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0.0) 100%
      );
    }
    @keyframes slide {
      0% {
        left: 0;
        opacity: 0;
      }
      5% {
        opacity: 1;
      }
      10% {
        left: 220px;
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  /*************************************************************/