#tree .leaf, #tree .cls-3 {
    transition: all .3s;
}

#tree .leaf:hover, #tree .cls-3:hover {
    cursor: pointer;
    transition: all .3s;
    /*transform: scale(1);*/
}

.tree {
    border: '1px solid red';
    box-sizing: 'border-box';
    width: 90%;
}

@media (min-width: 440px) {
    .tree {
        width: 400px;
    }
}