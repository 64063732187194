/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 35px;
  }
  
  .about .content ul li:first-child {
    margin-top: 35px;
  }
  
  .about .content ul i {
    background: #fff;
    box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
    font-size: 24px;
    padding: 20px;
    margin-right: 15px;
    color: #50AE5A;
    border-radius: 50px;
  }
  
  .about .content ul h5 {
    font-size: 18px;
    color: #555555;
  }
  
  .about .content ul p {
    font-size: 15px;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }