/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    /*height: 75vh;*/
    background: url("/public/assets/img/hero-bg.jpg") top left;
    background-size: cover;
    position: relative;
}

#hero:before {
    content: "";
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .container {
    position: relative;
}

#hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    /*line-height: 56px;*/
    color: #222222;
    font-family: "Poppins", sans-serif;
}

#hero h1 span {
    color: #50AE5A;
}

#hero h2 {
    color: #555555;
    margin: 5px 0 30px 0;
    font-size: 24px;
    font-weight: 400;
}

#hero .btn-get-started {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #50AE5A;
}

#hero .btn-get-started:hover {
    background: #50DE5A;
}

#hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: #222222;
    font-weight: 600;
    display: flex;
    align-items: center;
}

#hero .btn-watch-video i {
    color: #50AE5A;
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
}

#hero .btn-watch-video:hover {
    color: #50AE5A;
}

#hero .btn-watch-video:hover i {
    color: #50DE5A;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    /*#hero {
      height: 100vh;
    }*/

    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    #hero .btn-get-started,
    #hero .btn-watch-video {
        font-size: 13px;
    }
}

@media (max-height: 500px) {
    /*#hero {
      height: 120vh;
    }*/
}

/**********************************************/

.tree-grid {
    display: grid;
}

@media screen and (min-width: 1300px) {
    .tree-grid {
        grid-template-columns: max-content 100px max-content;
        column-gap: 100px;
        justify-items: start;
    }
}

@media screen and (max-width: 1300px) and (min-width: 1200px) {
    .tree-grid {
        grid-template-columns: max-content max-content;
        column-gap: 0;
        justify-items: center;
    }
}

@media screen and (max-width: 1200px) {
    .tree-grid {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}