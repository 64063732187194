/*--------------------------------------------------------------
# Supporters
--------------------------------------------------------------*/
.supporters {
    padding: 15px 0;
    text-align: center;
}

.supporters img {
    max-width: 75%;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    padding: 15px 0;
}

.supporters img:hover {
    transform: scale(1.25);
}

@media (max-width: 768px) {
    .supporters img {
        max-width: 40%;
    }
}