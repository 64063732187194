/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
    padding: 60px 0;
}

#team .container h5 {
    color: #50AE5A;
    padding-bottom: 5px;
}

.team .member {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(16, 110, 234, 0.15);
    max-width: 300px;
}

.team .member .member-img {
    position: relative;
    overflow: hidden;
}

.team .member img {
    transition: 0.3s;
}

.team .member:hover img {
    transform: scale(1.1);
    transition: 0.3s;
}

.team .member .social {
    position: absolute;
    left: 0;
    bottom: 30px;
    right: 0;
    opacity: 0;
    transition: ease-in-out 0.3s;
    text-align: center;
}

.team .member .social a {
    transition: color 0.3s;
    color: #222222;
    margin: 0 3px;
    padding-top: 7px;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    background: rgba(16, 180, 34, 0.8);
    display: inline-block;
    transition: ease-in-out 0.3s;
    color: #fff;
}

.team .member .social a:hover {
    background: #50DE5A;
}

.team .member .social i {
    font-size: 18px;
}

.team .member .member-info {
    padding: 25px 15px;
}

.team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #222222;
}

.team .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
}

.team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777777;
}

.team .member:hover .social {
    opacity: 1;
    bottom: 15px;
}

/*****************************************/

.team-img {
    width: 300px;
    /*height: 300px;*/
    object-fit: cover;
}

.member-bio {
    /*display: none;*/
    overflow: hidden;
    transition: all ease-in .5s;
    /*padding: 5px;*/
    /*position: relative;*/
    /*top: 100%;
  left: 0%;
  width: 80vw;*/
    height: 0;
    font-size: 12px;
}

.member:hover .member-bio {
    /*display: inline;*/
    transition: all ease-in .5s;
    height: 100%;
    /*width: 100vw;*/
    padding-bottom: 5px;
}