/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services .icon-box {
    padding: 10px 30px;
    margin: 4px;
    position: relative;
    overflow: hidden;
    background: #ffffff44;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 135px;
}

.featured-services .icon-box::before {
    content: "";
    position: absolute;
    background: #cbe0fb;
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    transition: all 0.3s;
    z-index: -1;
}

.featured-services .icon-box:hover::before {
    background: #50AE5A;
    top: 0;
    border-radius: 0px;
}

.featured-services .icon {
    margin-bottom: 15px;
}

.featured-services .icon i {
    font-size: 48px;
    line-height: 1;
    color: #50AE5A;
    transition: all 0.3s ease-in-out;
}

.featured-services .icon img {
    /*width: 48px;*/
    height: 48px;
}

.featured-services .icon svg {
    /*width: 48px;*/
    height: 58px;
    transition: all 0.6s ease-in-out;
}


.featured-services .title {
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 18px;
}

.featured-services .title a {
    color: #111;
}

.featured-services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
}

.featured-services .icon-box:hover .title a,
.featured-services .icon-box:hover .description {
    color: #fff;
}

.featured-services .icon-box:hover .icon i {
    color: #fff;
}

.featured-services .icon-box:hover .icon svg .st0 {
    fill: #fff;
    transition: all 0.6s ease-in-out;
    /*filter:invert(100%) sepia(0%) saturate(0%) hue-rotate(331deg) brightness(103%) contrast(101%)*/
}