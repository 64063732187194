
/*--------------------------------------------------------------
# Departments
--------------------------------------------------------------*/
.departments .nav-tabs {
    border: 0;
  }
  
  .departments .nav-link {
    border: 0;
    padding: 20px;
    color: #555555;
    border-radius: 0;
    border-left: 5px solid #fff;
    cursor: pointer;
  }
  
  .departments .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    transition: 0.3s;
  }
  
  .departments .nav-link p {
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .departments .nav-link:hover h4 {
    color: #50AE5A;
  }
  
  .departments .nav-link.active {
    background: #f7fcfc;
    border-color: #50AE5A;
  }
  
  .departments .nav-link.active h4 {
    color: #50AE5A;
  }
  
  .departments .tab-pane.active {
    -webkit-animation: slide-down 0.5s ease-out;
    animation: slide-down 0.5s ease-out;
  }
  
  .departments .tab-pane img {
    float: left;
    max-width: 300px;
    padding: 0 15px 15px 0;
  }
  
  @media (max-width: 768px) {
    .departments .tab-pane img {
      float: none;
      padding: 0 0 15px 0;
      max-width: 100%;
    }
  }
  
  .departments .tab-pane h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #50AE5A;
  }
  
  .departments .tab-pane p {
    color: #777777;
  }
  
  .departments .tab-pane p:last-child {
    margin-bottom: 0;
  }
  
  @-webkit-keyframes slide-down {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slide-down {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  
  