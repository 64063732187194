/*--------------------------------------------------------------
# Facility
--------------------------------------------------------------*/
.facility #facility-flters {
    padding: 0;
    margin: 0 auto 15px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
    padding: 2px 15px;
}

.facility #facility-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px 8px 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
}

.facility #facility-flters li:hover,
.facility #facility-flters li.filter-active {
    color: #50AE5A;
}

.facility #facility-flters li:last-child {
    margin-right: 0;
}

.facility .facility-item {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
}

.facility .facility-item .facility-info {
    opacity: 0;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
}

.facility .facility-item .facility-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #222222;
}

.facility .facility-item .facility-info p {
    color: #555555;
    font-size: 14px;
    margin-bottom: 0;
}

.facility .facility-item .facility-info .preview-link,
.facility .facility-item .facility-info .details-link {
    position: absolute;
    right: 10px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #3c3c3c;
}

.facility .facility-item .facility-info .preview-link:hover,
.facility .facility-item .facility-info .details-link:hover {
    color: #50AE5A;
}

.facility .facility-item .facility-info .details-link {
    right: 10px;
}

.facility .facility-item .facility-links {
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
}

.facility .facility-item .facility-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.facility .facility-item .facility-links a:hover {
    color: #50FE5A;
}

.facility .facility-item:hover .facility-info {
    opacity: 1;
    bottom: 20px;
}


.facility .facility-item img {
    transition: 0.3s;
}

.facility .facility-item:hover img {
    transform: scale(1.1);
    transition: 0.3s;
}

/*--------------------------------------------------------------
  # facility Details
  --------------------------------------------------------------*/
.facility-details {
    padding-top: 40px;
}

.facility-details .facility-details-slider img {
    width: 100%;
}

.facility-details .facility-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.facility-details .facility-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #50AE5A;
}

.facility-details .facility-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #50AE5A;
}

.facility-details .facility-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(34, 34, 34, 0.08);
}

.facility-details .facility-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.facility-details .facility-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.facility-details .facility-info ul li+li {
    margin-top: 10px;
}

.facility-details .facility-description {
    padding-top: 30px;
}

.facility-details .facility-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.facility-details .facility-description p {
    padding: 0;
}