/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    border: 1px solid #e2eefd;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #f1f6fe;
    border-radius: 4px;
    border: 1px solid #deebfd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
}

.services .icon-box .icon i {
    color: #50DE5A;
    font-size: 28px;
    transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #222222;
    transition: ease-in-out 0.3s;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
}

.services .icon-box:hover h4 a,
.services .icon-box:hover .icon i {
    color: #50AE5A;
}

.services .icon-box:hover .icon {
    border-color: #50AE5A;
}