/*--------------------------------------------------------------
# Mission
--------------------------------------------------------------*/
.mission .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.mission .content ul {
    list-style: none;
    padding: 0;
}

.mission .content ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 35px;
}

.mission .content ul li:first-child {
    margin-top: 35px;
}

.mission .content ul i {
    background: #fff;
    box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
    font-size: 24px;
    padding: 20px;
    margin-right: 15px;
    color: #50AE5A;
    border-radius: 50px;
}

.mission .content ul h5 {
    font-size: 18px;
    color: #555555;
}

.mission .content ul p {
    font-size: 15px;
}

.mission .content p:last-child {
    margin-bottom: 0;
}