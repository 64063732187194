/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq {
    padding: 60px 0;
}

.faq .faq-list {
    padding: 0;
    list-style: none;
}

.faq .faq-list li {
    border-bottom: 1px solid #d4e5fc;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.faq .faq-list .question {
    display: block;
    position: relative;
    font-family: #50AE5A;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 25px;
    cursor: pointer;
    color: #50AE5A;
    transition: 0.3s;
}

.faq .faq-list i {
    font-size: 16px;
    position: absolute;
    left: 0;
    top: -2px;
}

.faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 25px;
}

.faq .faq-list .icon-show {
    display: none;
}

.faq .faq-list .collapsed {
    color: black;
}

.faq .faq-list .collapsed:hover {
    color: #50AE5A;
}

.faq .faq-list .collapsed .icon-show {
    display: inline-block;
    transition: 0.6s;
}

.faq .faq-list .collapsed .icon-close {
    display: none;
    transition: 0.6s;
}