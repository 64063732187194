/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.pricing .box h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #777777;
    background: #f8f8f8;
}

.pricing .box h4 {
    font-size: 36px;
    color: #50AE5A;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
}

.pricing .box h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
}

.pricing .box h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
}

.pricing .box ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}

.pricing .box ul li {
    padding-bottom: 16px;
}

.pricing .box ul i {
    color: #50AE5A;
    font-size: 18px;
    padding-right: 4px;
}

.pricing .box ul .na {
    color: #ccc;
    text-decoration: line-through;
}

.pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
}

.pricing .btn-buy {
    background: #50AE5A;
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    transition: 0.3s;
}

.pricing .btn-buy:hover {
    background: #50DE5A;
}

.pricing .featured h3 {
    color: #fff;
    background: #50AE5A;
}

.pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #50AE5A;
    color: #fff;
}