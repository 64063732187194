/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  padding: 15px 0;
  transition: 0.3s;
  color: #222;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border: 0;
  border-bottom: 4px solid #e2e4e6;
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

@media (max-width: 575px) {
  .features .nav-link h4 {
    font-size: 16px;
    text-align: center;
  }
}

.features .nav-link:hover {
  color: #50AE5A;
}

.features .nav-link.active {
  color: #50AE5A;
  background-color: transparent;
  border-color: #50AE5A;
}

.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane h3 {
  font-weight: 700;
  font-size: 32px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.features .tab-pane h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 3px;
  background: #50AE5A;
  left: 0;
  bottom: 0;
}

.features .tab-pane h5 {
  font-weight: 700;
  font-size: 18px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.features .tab-pane h5:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 3px;
  background: #50AE5A;
  left: 0;
  bottom: 0;
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-top: 10px;
}

.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #50AE5A;
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}