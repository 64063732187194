/*--------------------------------------------------------------
# Pipeline
--------------------------------------------------------------*/
.pipelines .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
}

.pipelines .progress .pipeline {
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #222222;
}

.pipelines .progress .pipeline .val {
    float: right;
    font-style: normal;
}

.pipelines .progress-bar-wrap {
    background: #e2eefd;
    height: 10px;
}

.pipelines .progress-bar {
    width: 1px;
    height: 10px;
    transition: 0.9s;
    background-color: #50AE5A;
}