/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    background: #50AE5A;
    height: 40px;
    font-size: 14px;
    transition: all 0.5s;
    color: #fff;
    padding: 0;
}

#topbar .contact-info i {
    font-style: normal;
    color: #fff;
}

#topbar .contact-info i a,
#topbar .contact-info i span {
    padding-left: 5px;
    color: #fff;
}

#topbar .contact-info i a {
    line-height: 0;
    transition: 0.3s;
    transition: 0.3s;
}

#topbar .contact-info i a:hover {
    color: #fff;
    text-decoration: underline;
}

#topbar .social-links a {
    color: rgba(255, 255, 255, 0.7);
    line-height: 0;
    transition: 0.3s;
    margin-left: 20px;
}

#topbar .social-links a:hover {
    color: white;
}

#topbar .container {
    /*margin-left: 0;
    margin-right: 0;*/
}